<template>
    <div class="hezuohuoban">
        <div class="hezuohuoban_card">
            <div  v-for="(item,index) in datas" :key="index">{{ item.text }}</div>
<!--            <div>苏州市高职高专联席会议教学联盟</div>-->
<!--            <div class="last">苏州市先进制造业工程师学会</div>-->
        </div>

        <!--        返回home-->
        <div class="reqlist_back_home" v-on:click="toHome">
            <img src="assets/back_home.png"/>
        </div>

    </div>
</template>

<script>
    export default {
        name: "HeZuoHuoBan",
        data(){
            return {
                datas:[],
            }
        },
        mounted() {
            this.init();
        },
        methods:{
            init(){
                document.title="合作伙伴";
                let reqUrl='/api/other/partner';
                this.$post(reqUrl,null)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let data=rsp.data;
                            data.forEach(t=>{
                                this.datas.push(t);
                            })
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            toHome(){
                this.$router.push({path:'/'});
            }
        }
    }
</script>

<style scoped>
    .hezuohuoban{
        width: 750px;
    }
    .hezuohuoban_card{
        border-radius: 10px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 10px;
        padding-bottom: 30px;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
    }

    .hezuohuoban_card div{
        margin-left: 20px;
        margin-top: 20px;
    }

    .last{
        margin-bottom: unset !important;
    }




    .reqlist_back_home{
        width: 100px;
        height: 100px;
        background-color: white;
        border-radius: 100px;
        position: fixed;
        bottom: 30px;
        right: 30px;
        box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .reqlist_back_home img{
        width: 50px;
        height: 50px;
    }
</style>
